"use client";

import useScrollRestoration from "@/utils/hooks/useScrollRestoration";
import { HeroSection } from "@/(landing-page)/sections/HeroSection";

export default function HomePage() {
  useScrollRestoration();
  return (
    <main className="flex min-h-screen max-w-[100vw] flex-col">
      <HeroSection />
    </main>
  );
}
