import Link from "next/link";
import { LineShadowText } from "@/components/text/LineShadowText";

export const HeroSection = () => {
  return (
    <section
      id="hero-section"
      className="flex h-[100dvh] w-full flex-col items-center justify-center gap-24 overflow-hidden px-4 text-black ph:px-24 md:px-48 lg:px-72 xl:px-96"
    >
      <h1 className="text-balance text-7xl font-semibold leading-none tracking-tighter text-black sm:text-8xl md:text-9xl lg:text-[12rem]">
        <LineShadowText>Virtuosa</LineShadowText>
      </h1>
      <div className="flex max-w-2xl flex-col items-center gap-6 text-center">
        <p className="text-base sm:text-lg md:text-xl lg:text-2xl">
          Notre site est en cours de création. Préparez-vous à découvrir notre
          univers très bientôt.
        </p>
        <p className="text-sm font-light tracking-wide sm:text-base md:text-lg">
          Une idée ? Un projet ? Contactez-nous dès maintenant au 07 67 28 48 62
        </p>
      </div>
    </section>
  );
};
